<template>
  <div v-loading="loading" class="Content">

    <div v-if="contentData.length" class="content">
      <!-- 1文章、2视频、3公告、4三务公开、5精准扶贫  145一类 -->
      <div>
        <div v-for="item in contentData" :key="item.id" class="content-item click" @click="goDetail(item.id)">
          <img v-if="item.thumbnail || item.videoPoster" :src="item.thumbnail ? $beforeUrl(item.thumbnail) : $beforeUrl(item.videoPoster)">
          <div class="content-text">
            <div class="contentTitle oneLine">{{ item.title }}</div>
            <div class="rich" v-html="$setContent(item.context)" />
            <div>{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <div class="center">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="limit"
          class="pagination"
          :current-page="page*1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else-if="$parent.searchText"
      style="flex: 1; font-size: 100px; height: 500px"
      class="colCenter it"
    >
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1, // 当前页数
      contentData: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    limit() {
      return 9
    }
  },
  mounted() {

  },
  methods: {
    handleCurrentChange(val) {
      this.$changeParam({ page: val })
    },
    goDetail(id) {
      this.$router.push({ path: '/main/detail', query: { id }})
    },
    getData() {
      this.loading = true
      this.$store.dispatch('home/HomeGetSearch', { page: this.page, limit: this.limit, keywords: this.$parent.input.trim(), type: this.$parent.selected * 1 + 1 }).then(res => {
        if (res.code === 0) {
          this.total = res.count
          this.$parent.total = res.count
          this.contentData = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Content {
  width: 100%;
  overflow: hidden;
  .title {
    border-bottom: 1px solid #e0e0e0;
    height: 41px;
    .text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 4px solid var(--theme);
      height: 41px;
      display: inline-block;
    }
  }
  .content {
    .content-item {
      display: flex;
      height: 124px;
      border-bottom: 1px dotted #eee;
      padding-top: 20px;
      padding-bottom: 21px;
      box-sizing: content-box;
      img {
        width: 186px;
        height: 124px;
        margin-right: 16px;
      }
      .content-text {
        flex: 1;
        overflow: hidden;
        position: relative;
        div {
          width: 100%;
          display: block;
        }
        & > div:nth-child(1) {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        & > div:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 26px;
          word-break:break-all;
          margin-top: 13px;
        }
        & > div:nth-child(3) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
           position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .pagination {
    padding-top: 72px;
    padding-bottom: 72px;
  }

}
</style>
