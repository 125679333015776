<template>
  <div class="search">
    <div class="selectedOut">
      <div class="click" :class="{selected:selected==0}" @click="changeSelected(0)">文章</div>
      <div class="click" :class="{selected:selected==1}" @click="changeSelected(1)">视频</div>
    </div>
    <div class="searchK">
      <el-input ref="search" v-model="input" style="flex:1;" placeholder="请输入内容" @change="change" />
      <div class="center text click" @change="change">搜索</div>
    </div>
    <div v-show="searchText" class="searchTextOut">搜索<span class="searchText"> “{{ searchText }}” </span> 共有 {{ total }} 条记录</div>
    <div class="line" />
    <myContent ref="contents" />
  </div>
</template>

<script>
import myContent from './components/Content.vue'
export default {
  components: { myContent },
  data() {
    return {
      selected: 0,
      param: { },
      input: '',
      searchText: '',
      total: 0
    }
  },
  watch: {
    $route() {
      this.init()
      this.$refs.search.focus()
    }
  },

  mounted() {
    this.$refs.search.focus()
  },
  created() {
    this.init()
  },
  destroyed() {
  },

  methods: {
    changeSelected(select) {
      if (select === this.selected) return
      else {
        this.$changeParam({ selected: select }, true)
      }
    },
    init() {
      this.param = this.$getParams()
      this.selected = this.param.selected * 1 || 0
      this.input = this.param.input || ''
      this.searchText = this.param.searchText || ''
      this.$nextTick(() => {
        const contents = this.$refs.contents
        contents.page = this.param.page || 1
        if (this.searchText) { // 表示路由带了搜索,执行一次搜索
          this.$refs.contents.getData()
        } else {
          contents.contentData = []
        }
      })
    },
    change() {
      if (!this.input.trim()) {
        this.$message.error('请输入内容')
        this.input = ''
        return
      }
      this.$changeParam(
        {
          searchText: this.input,
          input: this.input,
          page: 1,
          selected: this.selected
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .search{
    margin: 0 auto;
    width: 795px;
    .selectedOut{
      padding-top: 15px;
      padding-bottom:20px;
      display: flex;
      div{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-right: 29px;
      }
      .selected{
        font-weight: bold;
        color: var(--theme);
      }
    }
    .searchK{
      margin-bottom: 29px;
      display: flex;
      ::v-deep{
        .el-input__inner{
          height:48px
        }
      }
      .text{
        width: 120px;
        height: 48px;
        background: var(--theme);
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .searchTextOut{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 15px;
      .searchText{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
      }
    }
    .line{
      height: 1px;
      background: #E0E0E0;
      margin-bottom: 20px;
    }

  }
</style>
